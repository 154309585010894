var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.9.27"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' ||
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'pre-production'
) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    autoSessionTracking: true,
    enableTracing: true,
    tracesSampleRate: 0.01,
    sampleRate: 0.1,
    ignoreErrors: [
      // Ignore errors by third party library
      'INVALID_STATE_ERR',
      'Request failed with status code 401',
      'Failed Refresh Token',
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured with value:',
      "null is not an object (evaluating 'component.style')",
      "Cannot read properties of null (reading 'ch')",
      't.v is null',
      'Network Error',
      'Request aborted',
      'timeout of 0ms exceeded',
      'No Access Token found',
      'Unexpected token {',
      // Ignore errors by browser extension
      "Cannot destructure property 'address' of '(intermediate value)' as it is undefined",
    ],
  });
}
